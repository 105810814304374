import * as React from "react";
import {useContext} from "react";
import GlobalContext from "../context/GlobalContext";
import {Link} from "gatsby";


const Breadcrumps = ({items}) => {
    const gContext = useContext(GlobalContext);
    return (
        <nav className="bg-primary hidden sm:flex items-center min-h-[50px]" aria-label="Breadcrumb">
            <ol className="max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-8 w-full mx-auto flex space-x-2">
                <li className="flex items-center">
                    <a href="/" className="text-sm sm:text-base font-light text-black hover:opacity-60 transition duration-150 ease-in-out">Home</a>
                </li>
                {items.map((item) => (
                    <li key={item.title} className="flex items-center">
                        <span className="text-sm sm:text-base text-black">/</span>
                        {item.href ? (
                            <Link to={gContext.url(item.href)} className="ml-2 text-sm sm:text-base text-black hover:opacity-60 transition duration-150 ease-in-out">{gContext.t(item.title)}</Link>
                        ) : (
                            <span className="ml-2 text-sm sm:text-base font-bold text-black cursor-pointer">{gContext.t(item.title)}</span>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    )
}
export default Breadcrumps
