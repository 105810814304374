import * as React from "react";
import {useContext} from "react";
import GlobalContext from "../context/GlobalContext";
import {GatsbyImage} from "gatsby-plugin-image";


const HeroPage = ({image, imageAlt, title, children, overlay}) => {
    const gContext = useContext(GlobalContext);
    return (
        <div className="relative w-full py-16 px-4 sm:px-6 lg:px-8 py-14">
            <div className="absolute inset-0">
                <GatsbyImage image={image}
                             alt={imageAlt}
                             loading="eager"
                             objectFit="cover"
                             objectPosition="50% 50%"
                             className="w-full h-full object-cover"/>
                {overlay ? (
                    <div className="absolute inset-0 bg-black/20 mix-blend-multiply"></div>
                ) : ('')}
            </div>
            <div className="relative min-h-[54vh] md:min-h-[600px] max-w-[1480px] w-full mx-auto flex items-center justify-center">
                {title ? (
                    <h1 className="z-10 title-hero">{gContext.t(title)}</h1>
                ) : (
                    <>
                        {children}
                    </>
                )}
            </div>
        </div>
    )
}
export default HeroPage
