import * as React from "react"
import MainLayout from '../layouts/main'
import {useContext} from "react";
import GlobalContext from "../context/GlobalContext";
import {graphql, useStaticQuery} from "gatsby";
import Breadcrumps from "../components/Breadcrumbs";
import Partners from "./home/partners";
import HeroPage from "../components/HeroPage";
import {GatsbyImage} from "gatsby-plugin-image";
import Help from "./home/help";
import SeoComponent from "../layouts/partials/SeoComponent";

const items = [
    { title: 'Contributi fiscali' }
]
const Contributi = ({pageContext}) => {
    const gContext = useContext(GlobalContext);
    const allImagesQuery = graphql `
            query {
              allFile(
                filter: {
                    relativeDirectory: { regex: "/(contributi)/" },
                    extension: {ne: "svg"}
                }
              ) {
                edges {
                  node {
                    base
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]        
                        placeholder: NONE       
                        quality: 100
                      )
                    }
                  }
                }
              }
            }
          `
    const {
        allFile: {edges: images},
    } = useStaticQuery(allImagesQuery)
    return (
        <MainLayout pageContext={pageContext}>
            <HeroPage
                image={gContext.getImageFromName(images, 'bg-contributi.jpg')}
                imageAlt={'bg-contributi.jpg'}>
                <div className="relative w-full mx-auto flex items-center justify-center">
                    <div className="absolute inset-0 flex items-center justify-center">
                        <img className="mx-auto w-full pointer-events-none max-w-[520px] opacity-70" width="520" height="499" src={'/ecobonus/ecobonus.svg'} alt="Ecobonus"/>
                    </div>
                    <h1 className="z-10 title-hero">{gContext.t('Contributi Fiscali')}</h1>
                </div>
            </HeroPage>

            <Breadcrumps  items={items}/>

           <div className="max-w-[1400px] mx-auto py-16 px-4 sm:px-6 lg:px-8 relative w-full">
               <h2 className="title-section text-center">{gContext.t('Caratteristiche generali')}</h2>
               <div className="w-full mt-10 text-justify" dangerouslySetInnerHTML={{ __html: gContext.t('contributo.content') }}></div>
               <div className="overflow-hidden rounded-xl mt-6 w-full max-w-[576px] mx-auto block md:hidden">
                   <GatsbyImage image={gContext.getImageFromName(images, 'banner-verticale.jpg')}
                                alt={'banner-verticale.jpg'}
                                loading="eager"
                                objectFit="contain"
                                objectPosition="50% 50%"
                                className="h-full w-full object-contain"/>
               </div>
               <div className="w-full overflow-hidden rounded-xl mt-6 hidden md:block">
                   <GatsbyImage image={gContext.getImageFromName(images, 'Banner_Vantaggi_2.jpg')}
                                alt={'Banner_Vantaggi_2.jpg'}
                                loading="eager"
                                objectFit="contain"
                                objectPosition="50% 50%"
                                className="h-full w-full object-contain"/>
               </div>
               <div className="mt-6" dangerouslySetInnerHTML={{ __html: gContext.t('contributo.link') }}></div>
           </div>

            <Help/>
            <Partners/>
        </MainLayout>
    )
}

export default Contributi

export const Head = ({pageContext}) => (
    <SeoComponent pageContext={pageContext} />
)