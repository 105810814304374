import * as React from 'react'
import {useContext, useEffect, useState} from "react";
import GlobalContext from "../../context/GlobalContext";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import {Autoplay} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

export default function Partners() {
    const gContext = useContext(GlobalContext);
    const allImagesQuery = graphql`
            query {
              allFile(
                filter: {
                    relativeDirectory: { regex: "/(partners)/" },
                    extension: {ne: "svg"}
                }
              ) {
                edges {
                  node {
                    base
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]    
                        placeholder: NONE          
                        quality: 100
                      )
                    }
                  }
                }
              }
            }
          `
    const {
        allFile: {edges: images},
    } = useStaticQuery(allImagesQuery)
    const [partner, setPartners] = useState({});
    useEffect(() => {
        setPartners({
            partner: [
                {name: 'Simta', image: 'simta.png'},
                {name: 'Somfy', image: 'somfy.png'},
                {name: 'Gamma', image: 'gamma.png'},
                {name: 'Mottura', image: 'mottura.png'},
                {name: 'Tempotest', image: 'tempotest.png'},
            ],
        })
    }, [gContext])
    return (
        <div className="relative w-full bg-gray-50 py-8 sm:py-16">
            <div className="max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-8 text-center space-y-6 sm:space-y-10">
                <h2 className="title-section text-center">{gContext.t('I nostri Partners')}</h2>
                <Swiper
                    className="swiper-partners"
                    loop
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    breakpoints={{
                        0: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                        },
                        900: {
                            slidesPerView: 2,
                            spaceBetween: 80,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 100,
                        },
                    }}
                >
                    {(partner?.partner || []).map((item) => (
                        <SwiperSlide key={item.name}>
                            <div className="flex items-center justify-center">
                                <span className="sr-only">{item.name}</span>
                                <GatsbyImage image={gContext.getImageFromName(images, item.image)}
                                             alt={item.image}
                                             loading="eager"
                                             objectFit="contain"
                                             objectPosition="50% 50%"
                                             className="w-full max-w-[320px] lg:max-w-auto" />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}
